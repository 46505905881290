import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { catchError, from, map, Observable, of, startWith } from 'rxjs';
import { ConfirmationResponse } from '../../assets/responses/Confirmation.response';
import { CreatePinForUserOnCallRequest } from '../../assets/requests/auth/CreatePinForUserOnCall.request';
import { LoginForUserWithPinRequest } from '../../assets/requests/auth/LoginForUserWithPin.request';
import { LoginUserResponse } from '../../assets/responses/LoginUser.response';
import {
  GetBusinessMonthlyVoucherSummaryRequest,
} from '../../assets/requests/discreteVouchers/GetBusinessMonthlyVoucherSummary.request';
import {
  GenerateBusinessVouchersInvoiceRequest,
} from '../../assets/requests/discreteVouchers/GenerateBusinessVouchersInvoice.request';
import {
  BusinessVouchersInvoiceModel,
} from '../../assets/models/accountingDocuments/BusinessAccountingDocument.model';
import { ContractedAgencyModel } from '../../assets/models/discreteVouchers/ContractedAgency.model';
import {
  BusinessWithProductEligibilityModel,
} from '../../assets/models/businesses/Business.model';
import {
  GetBusinessMonthlyOverviewResponse,
} from '../../assets/responses/businesses/GetBusinessMonthlyOverview.response';
import { BusinessMonthlySummaryReport } from '../../assets/models/businesses/BusinessSummaryReport.model';
import { InvoiceBusinessModel } from '../../assets/models/businesses/InvoiceBusiness.model';
import { SubmitInvoiceToChorusProRequest } from '../../assets/requests/chorusPro/SubmitInvoiceToChorusPro.request';
import {
  UpdateBusinessInternalInformationsRequest,
  BusinessInternalInformationsModel,
} from '../../assets/models/businesses/BusinessInternalInformations.model';
import { OnCallResponseData } from '../../assets/types/Serialization.type';
import { deserializeTimestampFields } from '../../assets/utils/functions/timestamp.util';

type BackendClient = {
  'AUTH-checkIsActivatedUser_onCall': {
    request: { email: string },
    response: { isActivated: boolean },
  },
  'AUTH-createPinForUser_onCall': {
    request: CreatePinForUserOnCallRequest,
    response: ConfirmationResponse,
  },
  'AUTH-loginForUserWithPin_onCall': {
    request: LoginForUserWithPinRequest,
    response: LoginUserResponse,
  },
  'BUSINESSES-listAllBusinessesOfBusinessAdmin_onCall': {
    request: { businessUserId: string },
    response: BusinessWithProductEligibilityModel[],
  },
  'BUSINESSES-getBusinessMonthlyOverview_onCall': {
    request: { businessId: string },
    response: GetBusinessMonthlyOverviewResponse,
  },
  'BUSINESSES-getContractedAgenciesOfBusiness_onCall': {
    request: { businessId: string },
    response: ContractedAgencyModel,
  },
  'BUSINESSES-getOneBusinessInvoices_onCall': {
    request: { businessId: string },
    response: InvoiceBusinessModel[] | null,
  },
  'DISCRETE_VOUCHERS-getBusinessMonthlyVoucherSummary_onCall': {
    request: GetBusinessMonthlyVoucherSummaryRequest,
    response: BusinessMonthlySummaryReport<'OPEN2PAY'>,
  },
  'DISCRETE_VOUCHERS-generateBusinessVouchersInvoice_onCall': {
    request: GenerateBusinessVouchersInvoiceRequest,
    response: BusinessVouchersInvoiceModel,
  },
  'CHORUS_PRO-submitBusinessInvoice_onCall': {
    request: SubmitInvoiceToChorusProRequest,
    response: ConfirmationResponse,
  },
  'BUSINESSES-updateBusinessInternalInformations_onCall': {
    request: UpdateBusinessInternalInformationsRequest,
    response: ConfirmationResponse,
  },
  'BUSINESSES-getBusinessInternalInformations_onCall': {
    request: {
      businessId: string
    },
    response: BusinessInternalInformationsModel | null,
  }
}

export enum State {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface LoadingState<T> {
  state: State;
  data?: T;
  error?: any;
}

@Injectable({
  providedIn: 'root',
})
export class CallerService {
  #functions: Functions = inject(Functions);

  onCall$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(
    functionName: FuncName,
    request: Req,
  ): Observable<Res> {
    return from(
      httpsCallable<Req, OnCallResponseData<Res>>(this.#functions, functionName)(request),
    ).pipe(
      map((result: HttpsCallableResult<OnCallResponseData<Res>>) => deserializeTimestampFields<Res>(result.data)),
    );
  }

  onCallWithLoadingState$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(functionName: FuncName, request: Req): Observable<LoadingState<Res>> {
    return this.onCall$<FuncName, Req, Res>(functionName, request).pipe(
      map((result: Res) => ({ state: State.Loaded, data: result })),
      catchError(error => of({ state: State.Error, error })),
      startWith({ state: State.Loading }),
    );
  }
}
